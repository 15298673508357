<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Si no recibiste el correo de verificación ingresa tu dirección para el reenvío
                    <br />Si aun no es miembro favor de 
                    <router-link tag="a" to="/register" class="white">registrarse</router-link>
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <img class="customLogo loginLogo" src="/static/img/logo-sole.svg">
                </router-link>
                <h6 class="mb-4">Reenvío de verificación</h6>
                <b-form v-if="!correoEnviado" class="av-tooltip tooltip-label-bottom">
                    <b-form-group :label="$t('Correo electrónico')" class="has-float-label mb-4">
                        <b-form-input type="text" name="usuario" v-model="$v.form.email.$model" :state="!$v.form.email.$error" />
                        <b-form-invalid-feedback v-if="!$v.form.email.required">Favor de ingresar tu correo electrónico</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.email.email">Favor de ingresar un correo electrónico valido</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.email.minLength">Tu correo debe contar con al menos 4 caracteres</b-form-invalid-feedback>
                    </b-form-group>
                    <div class="d-flex justify-content-between align-items-center">
                        <!--<router-link tag="a" to="/user/forgot-password">{{ $t('user.forgot-password-question')}}</router-link>-->
                        <b-button type="button" variant="primary" size="lg" :disabled="processingVerify" :class="{'btn-multiple-state btn-shadow': true,
                    'show-spinner': processingVerify,
                    'show-success': !processingVerify && loginError===false,
                    'show-fail': !processingVerify && loginError }" v-on:click="reenviarVerificacion()">
                            <span class="spinner d-inline-block">
                                <span class="bounce1"></span>
                                <span class="bounce2"></span>
                                <span class="bounce3"></span>
                            </span>
                            <span class="icon success">
                                <i class="simple-icon-check"></i>
                            </span>
                            <span class="icon fail">
                                <i class="simple-icon-exclamation"></i>
                            </span>
                            <span class="label">{{ $t('Reenviar') }}</span>
                        </b-button>
                    </div>
                </b-form>
                <div v-if="correoEnviado" class="alert alert-success" role="alert">{{respuestaEnviado}}</a></div>
            </div>
        </b-card>
    </b-colxx>
</b-row>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    validationMixin
} from "vuelidate";
const {
    required,
    maxLength,
    minLength,
    email
} = require("vuelidate/lib/validators");

export default {
    data() {
        return {
            form: {
                email: ""
            },
            respuestaEnviado: "",
            correoEnviado: false,
            processingVerify: false
        };
    },
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required,
                email,
                minLength: minLength(4)
            }
        }
    },
    computed: {
        ...mapGetters(["processing", "loginError", "forgotMailSuccess"])
    },
    methods: {
        reenviarVerificacion: function(){
            var instance = this;

            this.processingVerify = true;
            //instance.$parent.toggleLoader("Verificando cuenta");

            axios.request({
                method: "get",
                url:"/api/usuario/reenviartoken/" + instance.form.email + "/",
                //headers:{ Authorization: "Bearer " + token }
            }).then(response => {
                
                if(response.data.status == 200){
                    instance.respuestaEnviado = response.data.data;
                    instance.correoEnviado = true;
                }

                this.processingVerify = false;

                //instance.$parent.toggleLoader("Verificando cuenta");

            });
        }
    },
};
</script>
